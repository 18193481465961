/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./assets/css/tab.scss";
@import "./assets/css/utils.scss";
@import '@zetwerk/zetui/assets/zetui/scss/zetui';

/**
 * Fonts
 **/
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto/Roboto-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("assets/fonts/Roboto/Roboto-Medium.ttf") format("opentype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("assets/fonts/Roboto/Roboto-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("assets/fonts/Roboto/Roboto-Bold.ttf") format("opentype");
}

body {
  font-family: "Roboto-Regular" !important;
  margin: 0px;
}

*:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.ng-pristine.ng-valid.ng-touched {
  outline: none !important;
  box-shadow: none !important;
}

.eqs-required:after {
  content: " *";
  color: #eb6b6a;
}

.eqs-error {
  font-size: 11px;
  color: #eb6a6a;
}

/** EQS select */
.eqs-select {
  .ng-select-container {
    background: rgb(255, 255, 255);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    min-height: 32px;
    // height: 32px !important;
    line-height: 20px;
    font-size: 14px;
    box-shadow: none;
    outline: none !important;
    cursor: default;
    .ng-placeholder {
      color: rgb(125, 125, 125) !important;
    }
    .ng-value-container.ng-input {
      padding: 0px !important;
      margin-bottom: 0px;
    }
    .ng-value .ng-value-label {
      line-height: 20px;
      font-size: 12px;
      padding: 0px 5px;
    }
    .ng-input > input {
      color: rgb(74, 74, 74);
    }
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-size: 14px;
    line-height: 16px;
    padding: 6px 12px;
  }
}

.ng-select.ng-select-single .ng-select-container {
  height: 32px;
  .ng-value-container .ng-value .ng-value-label {
    font-size: 14px;
  }
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0px 0px;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: rgb(242, 242, 242);
}

.with-prefix,
.with-prefix-unit {
  display: flex;
  align-items: stretch;
  input {
    border-left: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .prefix {
    color: rgb(74, 74, 74);
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 6px 0px 6px 8px;
    text-align: center;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-right: 0px;
    margin-right: 0px;
    background: #fff;
    line-height: 20px;

    &.disabled-prefix {
      background: #f9f9f9;
    }
  }
}

.with-unit,
.with-prefix-unit {
  display: flex;
  align-items: stretch;
  width: 100%;
  input {
    border-right: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .unit {
    color: rgb(74, 74, 74);
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 4px 8px 4px 0px;
    text-align: center;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-left: 0px;
    margin-left: 0px;
  }
}

.same-row {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.join-column {
  margin-left: -1px;
}

.error-message {
  font-size: 11px;
  color: #eb6a6a;
  margin-bottom: 8px;
}

.eqs-button {
  font-size: 12px;
  font-family: "Roboto-Regular";
  font-weight: normal;
  padding: 6px 8px;
  border-radius: 3px;
  &.light {
    background: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
    color: rgb(74, 74, 74);
  }
  &.primary {
    background: rgb(0, 117, 227);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgb(255, 255, 255);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  }
  &.danger {
    background: rgb(223, 71, 89);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgb(255, 255, 255);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  }
}

.cursor {
  cursor: pointer;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
  margin-bottom: 8px !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.modal.fade-anim {
  background: #00000066 !important;
}

::ng-deep .singleFileColumn {
  min-width: 100%;
  max-width: 100%;
}

//For ngx-bootstrap version compatibility fix
.tooltip {
  position: absolute;
}
.tooltip-arrow {
  position: absolute;
}
