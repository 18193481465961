.zetwerk-tab {
  .nav-tabs {
    background: #fff;
    padding-left: 14px;
  }

  .nav-tabs .nav-link.active {
    font-size: 14px;
    font-weight: 500;
    color: #0079c9;
    border-bottom: 4px solid #0079c9;
    border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    font-family: "Roboto-Regular";
  }

  .nav-tabs .nav-link {
    font-size: 14px;
    color: #8d97ad;
    padding: 8px 0px;
    margin-left: 4px;
    margin-right: 24px;
    font-family: "Roboto-Regular";
  }

  .nav-tabs .nav-link:hover {
    color: #0079c9;
    border-color: rgb(255, 255, 255);
    border-bottom: 1px solid transparent;
    &.active {
      border-bottom: 4px solid #0079c9 !important;
    }
  }

  .tab-content {
    background: #fff;

    .table-heading {
      font-size: 14px;
      font-weight: normal;
      color: #8d97ad;
      padding: 16px 40px;
      height: 48px;
      box-shadow: inset 0 -1px 0 0 rgba(37, 42, 51, 0.2);
    }

    .table-row {
      font-size: 14px;
      font-weight: normal;
      padding: 16px 40px;
      min-height: 56px;
      box-shadow: inset 0 -1px 0 0 rgba(37, 42, 51, 0.12);
      align-items: center;
      .sub-item {
        color: #878a91;
      }

      .order-status {
        border-radius: 4px;
        background-color: #e0e0e0;
        font-size: 11px;
        font-weight: 500;
        color: #797979;
        padding: 5px 10px 5px 10px;
      }
    }
  }
}
