/* Grid definition */
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 16px;
  column-gap: 24px;
}
​
/* Column definitions */
.column_1 {
  grid-column: span 1;
}
.column_2 {
  grid-column: span 2;
}
.column_3 {
  grid-column: span 3;
}
.column_4 {
  grid-column: span 4;
}
​
/* Row definitions */
.row_1 {
  grid-row: span 1;
}
.row_2 {
  grid-row: span 2;
}
.row_3 {
  grid-row: span 3;
}
.row_4 {
  grid-row: span 4;
}
